import { df, momentInZone } from 'util/dateTime';
import { toI18n } from 'util/i18n';

import { ShiftWithLocation } from '../slice';

const I18N_KEY = 'dashboard.user.shift_trade_requests';

interface TradeableShift {
  start_at: string;
  end_at: string;
  role_name: string | null;
}

export const durationKey = (hours: number, minutes: number): string => {
  if (hours === 0) return `${I18N_KEY}.minutes`;
  if (minutes === 0)
    return hours === 1 ? `${I18N_KEY}.hour` : `${I18N_KEY}.hours`;
  return hours === 1
    ? `${I18N_KEY}.hour_and_minutes`
    : `${I18N_KEY}.hours_and_minutes`;
};

export const formatShift = (shift: ShiftWithLocation | TradeableShift) => {
  const startAt = momentInZone(shift.start_at);
  const endAt = momentInZone(shift.end_at);

  const durationMinutes = endAt.diff(startAt, 'minutes');
  const hours = Math.floor(durationMinutes / 60);
  const minutes = durationMinutes % 60;

  const durationStr = toI18n(durationKey(hours, minutes), {
    props: { hours, minutes },
  });

  const roleName = shift.role_name ? `(${shift.role_name})` : '';

  return {
    date: startAt.format(df('pretty_long_wday')),
    time: `${startAt.format(df('time_short'))} - ${endAt.format(
      df('time_short')
    )}`,
    isTradable: startAt >= momentInZone(),
    duration: durationStr,
    roleName,
  };
};
