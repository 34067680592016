import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toast from 'fe-design-base/molecules/Toast';
import { ToastProps } from 'fe-design-base/molecules/Toast/Toast';

import {
  selectIsToastOpen,
  selectToastContent,
  selectToastProps,
} from './selectors';
import { actions as globalToastActions } from './slice';

type Props = Omit<ToastProps, 'onClose'> & {
  onClose?: () => void;
};

const GlobalToast = () => {
  const toastProps = useSelector(selectToastProps);
  const isOpen = useSelector(selectIsToastOpen);
  const content = useSelector(selectToastContent);

  const dispatch = useDispatch();

  const { onClose, autoHide, hideCloseIcon, variant, action }: Props =
    toastProps;

  const handleClose = useCallback(() => {
    dispatch(globalToastActions.closeGlobalToast());
    onClose?.();
  }, [dispatch, onClose]);

  return (
    <Toast
      open={isOpen}
      onClose={handleClose}
      autoHide={autoHide}
      hideCloseIcon={hideCloseIcon}
      action={action}
      variant={variant}
    >
      {content}
    </Toast>
  );
};

export default GlobalToast;
