import { List } from 'immutable';
import { createSelector } from 'reselect';

import { toI18n } from 'util/i18n';

import { DEPARTMENT_NOT_SET } from './constants';
export const getLocationRoles = state =>
  state.getIn(['dailyReview', 'locationRoles']);
export const getLocationIssues = state =>
  state.getIn(['dailyReview', 'locationIssues']);
export const getLocationEmployees = state =>
  state.getIn(['dailyReview', 'locationEmployees']);

export const getDailyReviewLoading = state =>
  state.getIn(['dailyReview', 'isInitialDataLoading']) ||
  state.getIn(['dailyReview', 'isRowDataLoading']);
export const getFirstTimeGuideTooltipOpen = state =>
  state.getIn(['dailyReview', 'firstTimeGuideTooltipOpen']);
export const getFirstTimeGuideModalOpen = state =>
  state.getIn(['dailyReview', 'firstTimeGuideModalOpen']);
export const getShowActionSuccessToast = state =>
  state.getIn(['dailyReview', 'showActionSuccessToast']);
export const getShowActionSuccessToastText = state =>
  state.getIn(['dailyReview', 'actionSuccessToastText']);
export const getRoles = createSelector(getLocationRoles, roles =>
  roles?.reduce((data, role) => {
    let roleValue = role;
    if (role.get('department_name') === DEPARTMENT_NOT_SET) {
      roleValue = role.set('department_name', null);
    }

    data[role.get('id')] = roleValue;
    return data;
  }, {})
);

export const getEmployees = createSelector(getLocationEmployees, employees =>
  employees?.reduce((data, e) => {
    data[e.get('id')] = e;
    return data;
  }, {})
);

export const getRows = state => state.getIn(['dailyReview', 'rows']);

export const getPopulatedRows = createSelector(
  getRows,
  getEmployees,
  getRoles,
  getDailyReviewLoading,
  (rows, employees, roles, isLoading) => {
    if (isLoading) {
      return [];
    }

    const getRole = (row, allRoles) => {
      const role = allRoles[row.get('role_id')];
      return role;
    };

    const formatRows = (jobRows, allRoles, allEmployees) =>
      jobRows.map(row => {
        const user_id = row.get('user_id');
        const employee = allEmployees[user_id];
        const role = getRole(row, allRoles);
        const extraColumns = {
          id: row.get('id'),
          approved: row.get('approved'),
          locked_message: row.get('locked_message'),
          actual_start_at: row.get('actual_start_at'),
          employee: {
            id: user_id,
            name: employee.get('full_name'),
            initials: employee.get('initials'),
            avatar: employee.get('avatar_url'),
            department: role?.get('department_name'),
            role: role?.get('name') || '',
          },
          issues: row
            .get('issues')
            .filter(
              issue =>
                issue.get('type') !== 'late' &&
                issue.get('cta') !== null &&
                (!issue.get('resolved') ||
                  issue.get('type') !== 'auto_clock_out')
            ),
          actions: {
            issues: row
              .get('issues')
              .filter(
                issue =>
                  issue.get('type') !== 'late' &&
                  issue.get('cta') !== null &&
                  (!issue.get('resolved') ||
                    issue.get('type') !== 'auto_clock_out')
              ),
            shiftId: row.get('id'),
          },
          worked: {
            caption: row.get('caption'),
            duration: row.get('duration'),
            feedback: row.get('feedback'),
            notes: row.get('notes'),
            alerts: row
              .get('issues')
              .filter(
                issue =>
                  issue.get('type') === 'late' || issue.get('cta') === null
              ),
          },
        };

        row = row
          .delete('caption')
          .delete('duration')
          .delete('feedback')
          .delete('notes');

        return row.merge(extraColumns);
      });

    const populatedRows = formatRows(rows, roles, employees);

    return populatedRows;
  }
);

export const getDailyReviewFilters = state =>
  state.getIn(['dailyReview', 'filters']);

export const getDailyReviewRowIds = createSelector(
  [getPopulatedRows, getDailyReviewFilters],
  (rows, filters) => {
    const filteredRows = rows.filter(row => {
      // Filter by employee
      const employee = filters.get('employee');
      if (employee && row.getIn(['employee', 'id']) !== employee) {
        return false;
      }
      // Filter by role
      const roles = filters.get('roles')?.toJS();
      if (roles.length > 0 && roles.includes(row.get('role_id')) === false) {
        return false;
      }

      // Filter by issues
      const issues = filters.get('issues')?.toJS();
      if (issues.length > 0) {
        const rowIssues = row.get('issues').map(issue => issue.get('type'));
        if (issues.some(issue => rowIssues.includes(issue)) === false) {
          return false;
        }
      }

      // Return true if no filters are applied
      return true;
    });
    return filteredRows.map(row => row.get('id'));
  }
);

export const getDailyReviewFiltersCount = createSelector(
  [getDailyReviewFilters],
  filters => {
    if (filters.toJS) filters = filters.toJS();
    return Object.keys(filters).reduce((acc, key) => {
      if (
        Array.isArray(filters[key]) ? filters[key].length > 0 : filters[key]
      ) {
        acc++;
      }
      return acc;
    }, 0);
  }
);

export const getDailyReviewRow = createSelector(
  [getPopulatedRows, (_, props) => props.rowId],
  (rows, rowId) => rows.find(row => row.get('id') === rowId)
);

export const getPayrollIneligibleEmployees = state =>
  state.getIn(['dailyReview', 'needsReviewEmployees']);

export const getPayrollIneligibleEmployeesRowIds = createSelector(
  [getPayrollIneligibleEmployees, getDailyReviewFilters],
  (rows, filters) => {
    if (!rows) return List();
    const filteredRows = rows.filter(row => {
      const employee = filters.get('employee');
      if (employee && row.get('id') !== employee) {
        return false;
      }

      // Filter by issues
      const issues = filters.get('issues')?.toJS();
      if (
        issues.length > 0 &&
        !issues.find(issue => issue === 'missing_payroll_details')
      ) {
        return false;
      }

      return true;
    });
    return filteredRows.map(row => row.get('id'));
  }
);
export const getPopulatedPayrollIssueRows = createSelector(
  getPayrollIneligibleEmployees,
  getEmployees,
  getRoles,
  (ineligibleEmployees, employees, roles) => {
    const userIdToRoleIdMap = {};
    Object.values(employees).forEach(employee => {
      const userId = employee.get('user_id');
      const roleId = employee.get('role_id');
      userIdToRoleIdMap[userId] = roleId;
    });

    const populatedEmployees = ineligibleEmployees.map(employee => {
      const userId = employee.get('id');
      const roleId = userIdToRoleIdMap[userId];
      const role = roles[roleId];
      const issues = [
        {
          type: employee.has_check_address_error
            ? 'invalid_address'
            : 'missing_payroll_details',
        },
      ];
      const extraColumns = {
        id: userId,
        employee: {
          id: userId,
          name: employee.get('full_name'),
          initials: employee.get('initials'),
          avatar: employee.get('avatar_url'),
          department: role?.get('department_name'),
          role: role?.get('name') || '',
        },
        issues,
        actions: {
          issues,
        },
      };
      return employee.merge(extraColumns);
    });

    return populatedEmployees;
  }
);
export const getPayrollIneligibleEmployeesRow = createSelector(
  [getPopulatedPayrollIssueRows, (_, props) => props.rowId],
  (rows, rowId) => rows.find(row => row.get('id') === rowId)
);

export const getDailyReviewDate = state => state.getIn(['dailyReview', 'date']);

export const getDailyReviewIssuesCount = createSelector(
  [getPopulatedRows],
  rows => {
    const count = rows.reduce((acc, row) => acc + row.get('issues').size, 0);
    return count === 0 ? null : count;
  }
);

export const getEmployeeFilterOptions = createSelector(
  [getLocationEmployees],
  employees => {
    const employeeOptions = employees?.toJS().map(employee => ({
      value: employee.id,
      label: employee.full_name,
    }));
    return employeeOptions || [];
  }
);

export const getRoleFilterOptions = createSelector(
  [getLocationRoles],
  roles => {
    // group roles by department_name
    let groupedRoles = roles?.groupBy(role => role.get('department_name'));

    const truncateOptionLabel = roleName => {
      if (roleName.length > 30) {
        return roleName.substring(0, 30) + '...';
      }
      return roleName;
    };

    groupedRoles = groupedRoles
      ?.sortBy(
        // sort by department name
        (_, department) => department.toLowerCase()
      )
      ?.sortBy(
        // sort so that the DEPARTMENT_NOT_SET option is always displayed at the bottom of the list
        (_, department) => department === DEPARTMENT_NOT_SET
      );

    const roleOptions = groupedRoles?.reduce((acc, deptRoles, department) => {
      // add department option at the beginning of the list
      acc.push({
        groupLabel:
          department === DEPARTMENT_NOT_SET
            ? toI18n('timesheets.daily_review.filter.department_not_set')
            : truncateOptionLabel(department),
      });

      // add deptRoles in the department
      deptRoles
        .sortBy(role => role.get('name').toLowerCase())
        .forEach(role => {
          acc.push({
            value: role.get('id'),
            label: truncateOptionLabel(role.get('name')),
          });
        });
      acc.push({ divider: true });
      return acc;
    }, []);

    // remove the last divider from the roleOptions
    roleOptions?.pop();
    return roleOptions || [];
  }
);

export const getIssuesFilterOptions = createSelector(
  [getLocationIssues],
  issues =>
    issues?.toJS().map(issue => ({
      value: issue.type,
      label: issue.label,
    }))
);
export const getDailyReviewRowsApprovalStatus = state => {
  const rows = getPopulatedRows(state);
  const approvedRows = rows.filter(row => row.get('approved'));

  const allRowsLocked = rows.size === approvedRows.size;
  return allRowsLocked ? 'locked' : 'unlocked';
};
